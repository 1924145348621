import { createRouter, createWebHistory } from 'vue-router'
// import LayoutDefault from '../layouts/Default.vue'
// import LayoutW3 from '../layouts/W3.vue'

const router = createRouter({
	history: createWebHistory(),
	routes: [{
		path: '/',
		component: () => import('@/layouts/default.vue'),
		children: [{
			path: '',
			component: () => import('@/pages/home')
		}, {
			path: 'gyljr',
			component: () => import('@/pages/gyljr')
		}, {
			path: 'jsgl',
			component: () => import('@/pages/jsgl')
		}, {
			path: 'jsgl8',
			component: () => import('@/pages/jsgl8')
		}, {
			path: 'kjxy',
			component: () => import('@/pages/kjxy')
		}, {
			path: 'xfwz',
			component: () => import('@/pages/xfwz')
		}, {
			path: 'shangcheng',
			component: () => import('@/pages/shangcheng')
		}, {
			path: 'wuliu',
			component: () => import('@/pages/wuliu')
		}, {
			path: 'dianlan',
			component: () => import('@/pages/dianlan')
		}, {
			path: 'dianlan24',
			component: () => import('@/pages/dianlan24')
		}, {
			path: 'jjwz',
			component: () => import('@/pages/jjwz')
		}, {
			path: 'tgwz',
			component: () => import('@/pages/tgwz')
		}]
	},{
		path: '/w3/',
		component: () => import('@/layouts/w3.vue'),
		children: [{
			path: 'dianlan',
			component: () => import('@/pages/dianlanW3')
		}]
	}]
})

export default router
