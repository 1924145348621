import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import * as echarts from 'echarts';
import 'echarts-gl';
import 'echarts-liquidfill';
import axios from '@/assets/js/axios';
 
import locale from 'element-plus/lib/locale/lang/zh-cn';


let gprops = { };
gprops.$echarts = echarts;
gprops.$axios = axios;

gprops.$initChart = (chartObjs, chartName, targetDom) => {
	let chart = chartObjs != null ? chartObjs[chartName] : null;
	if (chart == null) {
		chart = echarts.init(targetDom);
		chartObjs[chartName] = chart;
	}
	return chart;
}

gprops.$moneyFmt = (number, divisor, digit) => {
	if (typeof number === 'string') number = Number(number);
	return (number / divisor).toFixed(digit);
}
gprops.$number = (number) => {
	if (number === undefined || number == null)
		return 0;
	else if (typeof number === 'string') 
		return Number(number);
	else
		return number;
}
gprops.$paramSum = (arr, param) => {
	let sum = 0;
	arr.forEach(ele => {
		sum += Number(ele[param]);
	})
	return sum;
}
gprops.$dateRate = (time) => {
	return ((new Date().getTime() - new Date(new Date().getFullYear() + '-01-01').getTime()) / (new Date(time).getTime() - new Date(new Date().getFullYear() + '-01-01').getTime()) * 100).toFixed(2);
}
gprops.$maxValue = (arr, param) => {
	let max;
	arr.forEach(ele => {
		let val = Number(ele[param]);
		if (max === undefined || max < val)
			max = val;
	})
	return max;
}
gprops.$objMaxValue = (obj) => {
	let max;
	for (let key in obj) {
		let val = Number(obj[key]);
		if (max === undefined || max < val) max = val;
	}
	return max;
}


// // 根据当前设备的时间，按固定分钟数刷新
// gprops.$refreshAtRegularMinute = (handler, refreshMinute) => {
// 	let timeMillis = new Date().getTime();
// 	let nextTimeout = timeMillis % refreshMinute * 60000;
// 	return setTimeout(() => {
// 		handler();
// 		gprops.$refreshAtRegularMinute(handler, refreshMinute);
// 	}, nextTimeout);
// }

// 根据当前设备的时间，按固定分钟数刷新
gprops.$refreshAtRegularMinute = (handler, refreshMinute) => {
	let timeMillis = new Date().getTime(), refreshMillis = refreshMinute * 60000;
	let nextTimeout = timeMillis % refreshMillis;
	nextTimeout = refreshMillis - nextTimeout;
	// console.log(timeMillis, nextTimeout);
	if (nextTimeout < 30000) nextTimeout += refreshMillis;
	return setTimeout(() => {
		handler();
		// gprops.$refreshAtRegularMinute(handler, refreshMinute);
	}, nextTimeout);
}

// 设定5分钟刷新数据
gprops.$refreshTimeout = (handler) => {
	return gprops.$refreshAtRegularMinute(handler, 2);
}
// 定时刷新机制
gprops.$refreshInterval = (handler, interval) => {
	let timeInterval = 0;
	if (interval) {
		try {
			timeInterval = Number(interval);
		} catch (error) {
			console.log(error);
		}
	}
	// 刷新时间小于3秒时，设为默认刷新时间 300 秒
	if (timeInterval < 3) timeInterval = 300;
	return setInterval(handler, timeInterval * 1000);
}

// 年份全局变量
gprops.$initYear = '';

const app = createApp(App);
Object.assign(app.config.globalProperties, gprops);

app.use(ElementPlus, { locale });
app.use(router);
// app.use(store);
app.mount('#app');

